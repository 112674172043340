import styled from "styled-components";

export const FixedSearch = styled.div`
  background-color: #fff;
  border-bottom: 1px solid #c6cdd4;
  position: sticky;
  top: 0;
  z-index: 10;
`;

export const ModalProductTitle = styled.span`
  max-width: 85%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ModalVariantTitle = styled.span`
  display: flex;
  gap: 20px;
  max-width: 67%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ThumbnailContainer = styled.div`
  margin-left: 3rem;
`;
